import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from "./Components/ui/provider.jsx";
import { defaultSystem } from "@chakra-ui/react";
import ScrollFromTop from './ScrollFromTop';

// Lazy load your components
const Home = lazy(() => import('./Pages/Home/Home.jsx'));
const AboutUs = lazy(() => import('./Pages/About/About.jsx'));
const Services1 = lazy(() => import('./Pages/WebDesigning/WebDesigning.jsx'));
const ContactUs = lazy(() => import('./Pages/ContactUs/ContactUs.jsx'));
const NotFound = lazy(() => import('./NotFound.jsx'));

// Matrix effect and styling for the preloader
const matrixStyle = {
  background: "#1c1c1c",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  color: '#fff',
  textAlign: 'center',
  fontSize: '1.7em',
  fontWeight: '800',
  flexDirection: 'column',
  gap: '20px' // Adds space between elements
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider value={defaultSystem}>
    <Router>
      <ScrollFromTop />
      <Suspense fallback={
        <div style={matrixStyle}>
          {/* "Web Matrix Solutions" text */}
          <div style={{
            fontSize: '1.8em',
            fontWeight: 'bold',
            color: '#fff',
            marginBottom: '20px'
          }}>
          <span style={{color:"#FF0000"}}>Web</span> <span style={{color:"#249ED9"}}>Matrix Solutions</span>
          </div>
          {/* Loading text */}
          <div>Loading...</div>
        </div>
      }>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/web-designing" element={<Services1 />} />
          <Route path="*" element={<NotFound />} /> {/* Fallback route */}
        </Routes>
      </Suspense>
    </Router>
  </Provider>
);
